import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enquiry-form',
  templateUrl: './enquiry-form.component.html',
  styleUrls: ['./enquiry-form.component.scss']
})
export class EnquiryFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    let z5oijpk1skkjpi;

    /* tslint:disable */
    (function(d, t) {
      const s: any = d.createElement(t);
      const options = {
        userName: 'rolleraustralia',
        formHash: 'z5oijpk1skkjpi',
        autoResize: true,
        height: '704',
        async: true,
        host: 'wufoo.com',
        header: 'show',
        ssl: true
      };
      s.src = ('https:' === d.location.protocol ? 'https://' : 'http://') + 'secure.wufoo.com/scripts/embed/form.js';
      s.onload = s.onreadystatechange = function() {
      const rs = this.readyState;
      if (rs) {
        if (rs !== 'complete') {
          if (rs !== 'loaded') {
            return;
          }
        }
      }
      // @ts-ignore TS2304
      try { z5oijpk1skkjpi = new WufooForm();
            z5oijpk1skkjpi.initialize(options);
            z5oijpk1skkjpi.display();
        } catch (e) {}};
      const scr = d.getElementsByTagName(t)[0];
      const par = scr.parentNode; par.insertBefore(s, scr);
      })(document, 'script');
      /* tslint:enable */
  }

}
